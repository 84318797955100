import React from "react";

import { createLeadView, LeadViewComponent } from "../_lib/view";

import { LeadStateProvider } from "../_lib/context";

import { LeadHeader } from "@leads/LeadHeader";
import { CreditScore } from "@leads/cards/CreditScore/Card";
import { HomeInformation } from "@leads/cards/HomeInformation";
import { EmploymentInformation } from "@leads/cards/EmploymentInformation";
import { CreditInquiries } from "@leads/cards/CreditInquiries";
import { TradeLines } from "@leads/cards/TradeLines";
import CollectionsBankRuptciesPublicRecords from "@leads/cards/CollectionsBankRuptciesPublicRecords";

import { Grid, GridItem } from "@leads/Grid";

const BasicView: LeadViewComponent = (props) => {
	const isProduction = process.env.GATSBY_ACTIVE_ENV === "production";

	return (
		<>
			<LeadHeader {...props} />

			<Grid>
				<GridItem sm={12}>
					<CreditScore />
				</GridItem>
				{!isProduction && (
					<GridItem>
						<CollectionsBankRuptciesPublicRecords />
					</GridItem>
				)}
				<GridItem>
					<TradeLines />
				</GridItem>
				<GridItem>
					<CreditInquiries />
				</GridItem>
				<GridItem>
					<HomeInformation />
				</GridItem>
				<GridItem>
					<EmploymentInformation />
				</GridItem>
			</Grid>
		</>
	);
};

const LeadView: LeadViewComponent = (props) => {
	const layoutProps = {
		...props,
		pageProps: props.params,
		title: "Lead Details",
		showBack: true,
		backTo: "/leads/",
	};

	return (
		<LeadStateProvider {...layoutProps}>
			<BasicView {...layoutProps} />
		</LeadStateProvider>
	);
};

export default createLeadView(LeadView, {});

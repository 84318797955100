import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	accordion: {
		boxShadow: "none",
		borderRadius: "0 !important",
		marginTop: "0 !important",
		marginBottom: "0 !important",
        backgroundColor: "#f5f5f7 !important",
        
        "& .MuiAccordionSummary-root.Mui-disabled": {
            opacity: 1,
        },
	},
	accordionDetails: {
		backgroundColor: "#ffffff",
		justifyContent: "center",
		padding: "16px 20px 16px",
	},
	detailsCard: {
		width: "100%",
		border: "none",
		minHeight: "150px",
		borderRadius: "4px",
	},
	icon: {
		marginRight: 8,
	},
}));
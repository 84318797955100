import React, { Fragment, useMemo } from "react";

import { get } from "lodash";

import { useLeadState } from "@leads/context";
import { defaultColumns } from "@leads/defaults";

import { transformToCurrency } from "@utils/transform";

import { getBureauProp } from "~/model/leads";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Typography } from "~/components/Typography";
import { InfoCard } from "~/components/InfoCard";
import RowGroupCard from "~/components/Cards/RowGroupCard/RowGroupCard";

import { useStyles } from "./styles";

import Success from "~/assets/vectors/check-circle.svg";
import Alert from "~/assets/vectors/alert-triangle-orange.svg";

import { Bankruptcy, Collection, SecuredLoan } from "@api/graphql/types";

const CollectionsBankRuptciesPublicRecords = () => {
	const {
		state: { lead, loading, error },
	} = useLeadState();

	const publicRecords: SecuredLoan[] = get(
		lead,
		getBureauProp("securedLoans"),
		{},
	);
	const bankruptcies: Bankruptcy[] = get(
		lead,
		getBureauProp("bankruptcies"),
		{},
	);
	const collections: Collection[] = get(
		lead,
		getBureauProp("collections"),
		{},
	);

	const styles = useStyles();

	const getBankruptciesText = (bankruptcies: Bankruptcy[]) => {
		if (bankruptcies?.length) {
			return `Bankruptcies (${bankruptcies?.length})`;
		}
		return (
			<Fragment>
				<Success className={styles.icon} />
				<Typography>No Bankruptcies Found</Typography>
			</Fragment>
		);
	};

	const getCollectionsText = (collections: Collection[]) => {
		if (collections?.length) {
			return `Collections (${collections?.length})`;
		}
		return (
			<Fragment>
				<Success className={styles.icon} />
				<Typography>No Collections Found</Typography>
			</Fragment>
		);
	};

	const bankruptciesText = getBankruptciesText(bankruptcies);
	const collectionsText = getCollectionsText(collections);

	const tableDefPublicRecords = (values: SecuredLoan[]) => ({
		title: "",
		values,
		columns: defaultColumns({
			col1: {
				width: 400,
				paddingRight: 4,
			},
		}),
		className: styles.detailsCard,
		selectors: {
			title: "dateFiled",
			attributes: [
				{
					display: "Date Filed:",
					property: "dateFiled",
				},
				{
					display: "Name, Address & Amount:",
					property: "SecuredLoanCreditorId",
				},
			],
		},
	});

	const tableDefBankruptcies = (values: Bankruptcy[]) => ({
		title: "",
		values,
		columns: defaultColumns({
			col1: {
				width: 400,
				paddingRight: 4,
			},
		}),
		className: styles.detailsCard,
		selectors: {
			title: "dateFiled",
			attributes: [
				{ display: "Date Filed:", property: "dateFiled" },
				{
					display: "Liability Amount:",
					property: "liabilityAmount",
					format: transformToCurrency,
				},
				{
					display: "Asset Amount:",
					property: "assetAmount",
					format: transformToCurrency,
				},
			],
		},
	});

	const tableDefCollections = (values: Collection[]) => ({
		title: "",
		values,
		columns: defaultColumns({
			col1: {
				width: 400,
				paddingRight: 4,
			},
		}),
		className: styles.detailsCard,
		selectors: {
			title: "",
			attributes: [
				{ display: "Date:", property: "assignedDate" },
				{
					display: "Creditor Name:",
					property: "agency.name",
				},
				{
					display: "Original Amount:",
					property: "originalAmount",
					format: transformToCurrency,
				},
				{
					display: "Balance Amount:",
					property: "balanceAmount",
					format: transformToCurrency,
				},
				{
					display: "Date of Last Payment:",
					property: "dateOfLastPayment",
				},
			],
		},
	});

	const publicRecordsList = useMemo(
		() => ({
			...tableDefPublicRecords(publicRecords),
			lead,
			loading,
			error,
		}),
		[lead, publicRecords, loading, error],
	);

	const bankruptciesList = useMemo(
		() => ({
			...tableDefBankruptcies(bankruptcies),
			lead,
			loading,
			error,
		}),
		[lead, bankruptcies, loading, error],
	);

	const collectionsList = useMemo(
		() => ({
			...tableDefCollections(collections),
			lead,
			loading,
			error,
		}),
		[lead, collections, loading, error],
	);

	const content = useMemo(() => {
		if (loading) {
			<Typography>Loading...</Typography>;
		}
		return (
			<Fragment>
				<Accordion
					className={styles.accordion}
					disabled={!bankruptcies?.length}
				>
					<AccordionSummary
						expandIcon={
							bankruptcies?.length ? <ExpandMoreIcon /> : null
						}
					>
						{bankruptciesText}
					</AccordionSummary>
					<AccordionDetails className={styles.accordionDetails}>
						{bankruptcies?.length ? (
							<RowGroupCard {...bankruptciesList} />
						) : (
							"No Bankruptcies"
						)}
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.accordion}
					disabled={!collections?.length}
				>
					<AccordionSummary
						expandIcon={
							collections?.length ? <ExpandMoreIcon /> : null
						}
					>
						{collectionsText}
					</AccordionSummary>
					<AccordionDetails className={styles.accordionDetails}>
						{collections?.length ? (
							<RowGroupCard {...collectionsList} />
						) : (
							"No Collections"
						)}
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.accordion}
					disabled={!publicRecords?.length}
				>
					<AccordionSummary
						expandIcon={
							publicRecords?.length ? <ExpandMoreIcon /> : null
						}
					>
						{publicRecords?.length
							? `Public Records (${publicRecords?.length})`
							: "Public Records (0)"}
					</AccordionSummary>
					<AccordionDetails className={styles.accordionDetails}>
						{publicRecords?.length ? (
							<RowGroupCard {...publicRecordsList} />
						) : (
							"No Public Records"
						)}
					</AccordionDetails>
				</Accordion>
				<Accordion disabled className={styles.accordion}>
					<AccordionSummary>
						<Alert className={styles.icon} />
						<Typography>
							Other items found require a hard-pull to view
						</Typography>
					</AccordionSummary>
				</Accordion>
			</Fragment>
		);
	}, [loading, bankruptcies, publicRecords]);

	return (
		<Fragment>
			<InfoCard title="Bankruptcies, Collections & Public Records">
				{content}
			</InfoCard>
		</Fragment>
	);
};

export default CollectionsBankRuptciesPublicRecords;
